.variation-entry-qty {
    font-size: 20px;
    line-height: 45px;
}

#page-record-loader {
    background-color: #a4a6ac00;
}

// .loader-container {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     height: 100vh;
//     /* Adjust as needed */
//     background-color: rgba(255, 255, 255, 0.8);
//     /* Optional: semi-transparent background */
// }

// .spinner {
//     width: 40px;
//     height: 40px;
//     position: relative;
// }

// .double-bounce1,
// .double-bounce2 {
//     width: 100%;
//     height: 100%;
//     border-radius: 50%;
//     background-color: #3498db;
//     opacity: 0.6;
//     position: absolute;
//     top: 0;
//     left: 0;
//     animation: bounce 2s infinite ease-in-out;
// }

// .double-bounce2 {
//     animation-delay: -1s;
// }

// @keyframes bounce {

//     0%,
//     100% {
//         transform: scale(0);
//     }

//     50% {
//         transform: scale(1);
//     }
// }