.loading_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 182vh;
  background: rgba(125,125,125,0.5);
  color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; }

.svg {
  width: 15%;
  height: 15%;
 }  // margin-top: 20%


