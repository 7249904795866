/* style.css */
.autocomplete-container {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 20px;
}

.autocomplete-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
}

.autocomplete-suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    // border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;

    max-height: 200px;
    /* Adjust the height limit as needed */
    overflow-y: auto;
    /* Enable vertical scrolling */
    overflow-x: hidden;
    /* Disable horizontal scrolling */
}

.autocomplete-suggestion {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.autocomplete-suggestion:hover {
    background-color: #f0f0f0;
}