.date-picker {
    display: flex;
    gap: 6px;
    /* Optional: Adds space between the items */
}

.date-picker .DateRangePicker__keyboardShortcuts {
    display: none !important;
}

.date-picker .DayPickerKeyboardShortcuts_buttonReset {
    display: none !important;
}

.date-picker .SingleDatePicker_picker {
    z-index: 2;
}

.date-picker .to-date-picker .SingleDatePicker_picker__directionLeft {
    left: -156px !important;
}

.date-picker .DateInput {
    width: 100%;
}

.date-picker .SingleDatePickerInput__withBorder {
    border: none;
}